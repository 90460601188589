import React, {useEffect, useState, useRef} from "react";
import {Link} from "gatsby";
import {Container, Row, Col, Button, Form, InputGroup, FormControl, OverlayTrigger, Tooltip} from "react-bootstrap";
import {BsInfoCircle} from "react-icons/bs";
import {useForm} from "react-hook-form";
import CountUp from "react-countup";
import imgSaveMoney from "../../../assets/image/svg/save-money-with-camelo-scheduling.svg";

const currencyPrefix = "$"; // NOTE should be regionalized later

const formatOutput = (v) => {
  let margin = 0.042;
  let lower = v * (1 - margin);
  let upper = v * (1 + margin);

  let shortenNumber = (e) => {
    var t, e = 1e6 < e ?
      (t = Number(e / 1e6).toFixed(2), "m") :
      (t = Math.round(e / 1e3), "k");
    return "".concat(t).concat(e)
  }

  return `${currencyPrefix}${shortenNumber(lower)} - ${currencyPrefix}${shortenNumber(upper)}`;
};

const calculateROI = ({
  numberOfEmployees,
  avgEmployeeHourlyRate,
  adminHoursPerWeek,
  avgManagerSalary,
}, setMonthlySavings, setAnnualSavings, setReturnOverInvestmentRate) => {
  const employeesAvgHours = 40; // hours per week
  const totalWeek = 52;
  const laborCostReduction = 0.042;
  const adminTimeReduction = 0.5;
  const needOneAdminPerNumberEmployees = 15;
  const monthlyCostPerUser = 3.0; // NOTE: this should be regionalized based on geolocation and currency

  let employeesCostSavings = totalWeek * numberOfEmployees * employeesAvgHours * avgEmployeeHourlyRate * laborCostReduction;

  let numberOfManagers = Math.ceil(numberOfEmployees / needOneAdminPerNumberEmployees);
  let managerHourlyRate = (avgManagerSalary / totalWeek / employeesAvgHours);
  let adminCostSavings = totalWeek * numberOfManagers * adminHoursPerWeek * managerHourlyRate * adminTimeReduction;

  let initialInvestment = (Number(numberOfEmployees) + numberOfManagers) * monthlyCostPerUser * 12;
  let netGains = (employeesCostSavings + adminCostSavings) - initialInvestment;
  let roi = (initialInvestment > 0 ? (netGains / initialInvestment) : 0);

  let monthlySavings = netGains / 12;
  let annualSavings = netGains;

  setMonthlySavings(formatOutput(monthlySavings));
  setAnnualSavings(formatOutput(annualSavings));
  setReturnOverInvestmentRate(roi);
}


const ROICalc = () => {
  const [monthlySavings, setMonthlySavings] = useState(0);
  const [annualSavings, setAnnualSavings] = useState(0);
  const [returnOverInvestmentRate, setReturnOverInvestmentRate] = useState(0);
  const prevROIRef = useRef();

  const {register, getValues} = useForm({
    defaultValues: {
      numberOfEmployees: 30,
      avgEmployeeHourlyRate: 20,
      adminHoursPerWeek: 12,
      avgManagerSalary: 60000,
    },
  });

  const handleInputChange = () => {
    prevROIRef.current = returnOverInvestmentRate;
    calculateROI(getValues(), setMonthlySavings, setAnnualSavings, setReturnOverInvestmentRate);
  }

  useEffect(() => {
    handleInputChange();
  }, [getValues()]);

  return (
    <>
      <Container className="pt-24 pt-lg-32">
        <Row className="justify-content-center">
          <Col xl="9" lg="9">
            <div className="px-md-12 text-center mb-9 mb-lg-18">
              <h2 className="title gr-text-4 mb-8">
                What you actually gain from using Camelo
              </h2>
              <p>
                Reduce costs, save time, and create a better work-life balance. <br /> See how much money your business can save after using Camelo.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="roi-calculator justify-content-center align-items-scretch overflow-hidden bg-default-3 border rounded-10">
          <Col xs="12" lg="8" className="px-8 py-8 px-xl-16 pt-xl-16 ">
            <Form.Group>
              <Form.Row className="mb-6">
                <Form.Label column="md" xs={12} lg={6}>
                  Number of employees
                </Form.Label>
                <Col>
                  <InputGroup className="">
                    <Form.Control
                      type="number"
                      name="numberOfEmployees"
                      placeholder="0"
                      ref={register}
                      onChange={handleInputChange}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text className="roi-calc-appendField">employees</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Form.Row>
              <Form.Row className="mb-6">
                <Form.Label column="md" xs={12} lg={6}>
                  Their average hourly rate
                </Form.Label>
                <Col>
                  <InputGroup className="">
                    <Form.Control
                      type="number"
                      name="avgEmployeeHourlyRate"
                      placeholder="0"
                      ref={register}
                      onChange={handleInputChange}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text className="roi-calc-appendField">$ / hour</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Form.Row>
              <Form.Row className="mb-6">
                <Form.Label column="md" xs={12} lg={6}>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Total hours per week that managers spent scheduling + additional time on timesheets.</Tooltip>}
                  >
                    <div>
                      Hours spent scheduling/week
                      <span className="pl-6 text-primary">
                        <BsInfoCircle></BsInfoCircle>
                      </span>
                    </div>
                  </OverlayTrigger>
                </Form.Label>
                <Col>
                  <InputGroup className="">
                    <Form.Control
                      type="number"
                      name="adminHoursPerWeek"
                      placeholder="0"
                      ref={register}
                      onChange={handleInputChange}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text className="roi-calc-appendField">hours / week</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>

                </Col>
              </Form.Row>
              <Form.Row className="mb-6">
                <Form.Label column="md" xs={12} lg={6}>
                  Average salary of manager
                </Form.Label>
                <Col>
                  <InputGroup className="">
                    <Form.Control
                      type="number"
                      name="avgManagerSalary"
                      placeholder="0"
                      ref={register}
                      onChange={handleInputChange}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text className="roi-calc-appendField">$ / year</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>

                </Col>
              </Form.Row>
              <div className="text-muted">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>We calculate this based on survey data of our existing customers about their total time saved from business admin and employees' productivity management.</Tooltip>}
                >
                  <small style={{borderBottom: "1px dotted #aaa"}}>
                    How we calculate this?{" "}
                  </small>
                </OverlayTrigger>
              </div>
            </Form.Group>
          </Col>
          <Col className="bg-default-1 position-relative px-8 py-8 px-xl-16 py-xl-16 d-flex flex-column " xs="12" lg="4">
            <img src={imgSaveMoney}
              data-aos="fade-left"
              data-aos-delay="500"
              data-aos-duration="1200"
              style={{width: "30%", position: "absolute", bottom: 0, right: 20}} />
            <div className="saving-result">
              <div className="gr-text-5 text-black">
                <strong>{monthlySavings}</strong>
              </div>
              <p className="gr-text-12">Monthly Savings After Setup</p>
            </div>
            <div className="saving-result pt-8">
              <div className="gr-text-5 text-black">
                <strong>{annualSavings}</strong>
              </div>
              <p className="gr-text-12">Yearly Savings After Setup</p>
            </div>
            <div className="pt-8">
              <div className="gr-text-5 text-black">
                <strong>

                  <CountUp
                    duration={0.42}
                    decimal="."
                    decimals={1}
                    start={prevROIRef.current}
                    end={returnOverInvestmentRate}
                  />
                </strong>
                {"  "}<span className="gr-text-color-opacity gr-text-6">times</span>
              </div>
              <p className="gr-text-12">Return on Investment</p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ROICalc;
